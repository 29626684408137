/*
_colors.css
=========
all color variables used across all apps
*/


:root {
	--primary: #0f1e14;
	--accent: #FF6D70;
	--tertiary: #0750A8;
	--primary-dark: #0E1221;
	--secondary: #6c757d;
	--secondary-dark: #565e64;
	--secondary-light: #c6c7c8;
	--success: #518404;
	--success-dark: #416a03;
	--info: #0dcaf0;
	--info-dark: #0aa2c0;
	--info-subtle: #eaf6fe;
	--info-subtle-dark: #c5e6f2;
	--warning: #ffc107;
	--warning-dark: #cc9a06;
	--danger: #e94b00;
	--danger-vivid: #b90202;
	--danger-dark: #bc3d00;
	--danger-transparent: #e94b0042;

	--black: #000;
	--dark: #121212;

	--white: #fff;
	--light: #f8f9fa;
	--gray-light: #efefef8c;

	--gray: #efefef;
	--grey: #808080;
	--gray-dark: #bfbfbf;

	--disabled: #cecece;
	--disabled-light: #e9ecef;
	--disabled-dark: #707070;

	--progress-bar-state-25: #e94b00;
	--progress-bar-state-50: #ffc107;
	--progress-bar-state-75: #0a63af;
	--progress-bar-state-100: #518404;

	--dark-shadow-transparency-29: #16161629;
	--black-shadow-transparency-29: #00000029;

	--table-row-background-odd: #e9e9e9;
	--table-row-background-even: #f9f9f9;

	--card-highlight-blue: #132556;
	--value-highlight-blue: #294593;

	--chat-message-text-color: #666;
	--chat-message-box-shaddow-color: #e0dfe0;
	--chat-message-nametime-color: #979b9f;

	/* Graph colors */
    --graph-blue-1: #0E1221;
    --graph-blue-2: #294593;
    --graph-blue-3: #456CD8;
    --graph-blue-4: #A5B5FF;

    --graph-orange-1: #BC3D00;
    --graph-orange-2: #E94B00;
    --graph-orange-3: #FF712E;
    --graph-orange-4: #FF905A;

    --graph-cyan-1: #0D747C;
    --graph-cyan-2: #2BA8B2;
    --graph-cyan-3: #52CCD6;
    --graph-cyan-4: #8CE4EB;

    --graph-turquoise-1: #006454;
    --graph-turquoise-2: #00977F;
    --graph-turquoise-3: #0FC7AA;
    --graph-turquoise-4: #72E2D0;

    --graph-green-1: #395D01;
    --graph-green-2: #518404;
    --graph-green-3: #8AC138;
    --graph-green-4: #BAE578;

	/* Gradients */
	--gradient-start-blue: #162A60;
	--gradient-end-blue: #0D1C46;

	--gradient-start-orange: var(--danger);
	--gradient-end-orange: var(--danger-dark);

	--gradient-start-gray: #F8F8F8;
	--gradient-end-gray: var(--gray);

	/* Bootstrap */
	--bs-primary-rgb: rgb(15, 30, 20);

	/* Hover & pressed */
	--hover: var(--accent);
	--hover-dark: var(--dark);

	--link-color: var(--tertiary);
	--link-hover-color: var(--dark);
}