/* 
Accounts Css
Css for Allauth forms and all forms connected with
login and register process
including mfa and sso forms
*/

.reset-password {
    margin-bottom: 8.15rem;
}

.account-forms-text {
    display: block;
    margin-bottom: 0.35rem;
}

#div_id_password2 > div,
#div_id_oldpassword > div,
#div_id_password > div, #div_id_password1 > div,
#div_id_new_password1 > div {
    position: relative;
}

#div_id_first_name label,
#div_id_last_name label {
    margin-bottom: 0.71rem;
}

.create-password-form > #div_id_terms_accepted {
    margin-top: 2rem;
}

#div_id_terms_accepted .asteriskField {
	display: none;
}

#div_id_terms_accepted label {
	font-weight: normal;
}

.fakePasswordLabel {
    font-size: 1rem;
    line-height: .75rem;
    margin-top: .31rem;
    margin-bottom: .88rem;
}

.form-control.fakePassword {
    position: relative;
    line-height: 1.5;
    margin-left: 0.8rem;
    margin-bottom: 1.3rem;
    background-color: var(--white);
    padding: 0.75rem 0.75rem 0.6rem 0.75rem;
}

#div_id_membership > .form-control:disabled {
    margin-bottom: -2rem;
}


/*  eye icon styles for Edge*/
@supports (-ms-ime-align:auto) {
    #div_id_password2 > div > span.show-hide-password {
        position: absolute;
        float: right;
        right: 5.5rem;
        top: 61.6%!important;
    }
}

/* remove default eye icon in Edge*/
::-ms-reveal,
input::-ms-reveal,
input::-ms-clear {
    display: none;
}

#div_id_auth-password > div > span.show-hide-password {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#div_id_auth-password > div > span.show-hide-password > a.eye-hide,
#div_id_auth-password > div > span.show-hide-password > a.eye-show {
    position: relative;
    right: 1.3rem;
    bottom: 2.2rem;
}

#div_id_auth-password > div > #error_1_id_auth-password {
    position: absolute;
}

#div_id_password2 > div > span.show-hide-password,
#div_id_oldpassword > div > span.show-hide-password,
#div_id_new_password1 > div > span.show-hide-password,
#div_id_password > div > span.show-hide-password,
#div_id_password1 > div > span.show-hide-password {
    position: absolute;
    right: 1rem;
    top: 0.6rem;
    line-height: 2rem;
}

a.forgotten-password.underline-link {
    text-decoration: underline;
}

a.forgotten-password.underline-link:hover {
    text-decoration: none;
}

.loginformdiv .asteriskField{
    color: var(--danger);
    margin-left: 0.12rem;
}

/* login form */

#hint_id_password,
#hint_id_password1 {
    display: block;
}

.fa-user,
.fa-user-plus,
.fa-sign-out {
    margin-right: 0.37rem;
}

.fa-circle-check {
    font-size: 1.7rem;
    color: var(--success);
    margin-right: 0.62rem;
}


.account-form {
    border-radius: 0.37rem;
    z-index: 3;
}

.signout-link,
.signin-link,
.buttons {
    text-align: center;
}


#div_id_email > label {
    margin-bottom: 0.71rem;
}

.reset-password-form * #submit-id-submit {
    display: block;
    margin: 3.92rem auto 0rem auto;
}

/* mfa styling */

#id_generator-token:hover,
#id_generator-token:active,
#id_generator-token:focus,
#id_validation-token:hover,
#id_validation-token:active,
#id_validation-token:focus,
#id_token-otp_token:hover,
#id_token-otp_token:active,
#id_token-otp_token:focus{
    border-color: var(--bs-body-color)!important;
    box-shadow: none!important;
}

.wizard-form > #id_generator-token,
#id_token-otp_token {
    padding: 0.75rem 0.75rem 0.6rem 0.75rem;
    border-radius: 0.71rem;
    border-color: var(--secondary-light);
    background-color: var(--white) !important;
}

.mfa-token-field label {
    display: none;
}

div.form-error-styles + input + .alert-danger {
    --bs-alert-bg: none;
    --bs-alert-border-color: none;
	padding: 0;
}

div.form-error-styles + input + .alert-block ul {
	list-style-type: none;
	padding-left: 0;
}
