/*
base.css
=========
* custom css used across all apps
* wide css selectors that include html elements
* styles for the main navbar
* styles for the footer
* media queries for all above
*/

html, body {
	font-family: var(--primary-font);
	font-size: 15px;
}

body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.background-image {
    background-image: url('../img/home-page-background.webp');
    background-size: cover;
    background-repeat: no-repeat;
}

.rounded-10px {
	border-radius: 0.71rem;
}

.hover-shadow {
	transition: all 0.3s ease-in-out;
}

.hover-shadow:hover {
	box-shadow: rgba(50, 50, 93, 0.25) 0rem 0.4286rem 0.8571rem -0.1429rem, rgba(0, 0, 0, 0.3) 0rem 0.2143rem 0.5rem -0.2143rem;
}


/* #Table styles */


.report-table {
    width: 100%;
}

.report-table thead > tr {
    vertical-align: middle;
    background-color: var(--white);
}

.report-table>tbody>tr:nth-child(odd)>td {
    background-color: var(--gray);
 }

.report-table th {
    padding: 1.25rem 0.62rem;
    font-size: 1rem;
}

.report-table td {
    padding: 0.75rem 0.62rem;
    font-size: 0.87rem;
}

.report-table td:last-child,
.report-table th:last-child {
  border-radius: 0 0.40rem 0.40rem 0;
}

.report-table td:first-child,
.report-table th:first-child {
    border-radius: 0.40rem 0 0 0.40rem;
}

.btn.show {
    color: var(--dark);
    background-color: var(--bs-btn-bg);

}

.form-input {
    height: 3.35rem;
    text-indent: 2.35rem;
    border-radius: 0.71rem;
    background-color: var(--gray);
    border: 0;
}

.header-text {
    font-size: 3.5vw;
    font-weight: $font-weight-bold;
}

.fa-book-open {
    color: var(--danger);
}

/* sign out modal styles */
.sign-out-modal {
    margin-top: 13%
}

.sign-out-modal-content,
.delete-modal-content {
    width: 110%;
    padding: 0.4rem;
}

.sign-out-header,
.delete-header {
    border-bottom: 0;
}

.sign-out-body,
.delete-modal-body {
    padding: 0 2.5rem 1.62rem 2.5rem;
}

.sign-out-cancel {
    margin-right: 0.93rem;
}

.sign-out-badge {
    color: var(--danger);
    font-size: 1.8rem;
    margin-right: 0.5rem;
}

#confirmation-dialog {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-height: 100vh;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.6);
}

#confirmation-dialog > .dialog {
    background-color: var(--white);
    width: 100%;
    max-width: 40rem;
    padding: 2rem;
    margin: 1em;
    border-radius: 0.57rem;
}

#confirmation-dialog .title {
    font-size: 2.5em;
    font-weight: $font-weight-bold;
    margin-bottom: 0.5em;
}

#confirmation-dialog .title button {
    height: 0.12rem;
    width: 0.12rem;
}

#confirmation-dialog .body {
    margin-bottom: 1.5em;
}

/* header in base.html */

.disabled-icon {
    color: var(--disabled) !important;
}

/* truncate */

table a, .truncate {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.card .truncate {
    max-width: 13rem;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

table a, table .truncate {
    max-width: 400px;
}

strong {
    font-weight: $font-weight-bold;
}

th {
  font-weight: $font-weight-bold;
}


