/*
form.css
=========
* css selectors on html elements of the form
* css selectors on bootstrap classes within form
* customised styles for widgets, crispy fields, form fields, components used in form
*/


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 2.14rem var(--white) inset !important;
}

// removes font-family from hide password black dots in firefox
@-moz-document url-prefix() {
    input[type=password]:not(:placeholder-shown) {
        font-family: 'pass';
    }
}

.form-control,
.dynamic-form>td>div>input {
    padding: 0.75rem 0.75rem 0.6rem 0.75rem;
    border-radius: 0.71rem;
    border-color: var(--secondary-light);
    background-color: var(--white) !important;
}

.dynamic-form > td > div > input {
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--dark);
    background-color: var(--white);
    background-clip: padding-box;
    border: 0.07rem solid var(--secondary-light);
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

.form-control:hover, .form-control:active, .form-control:focus,
.dynamic-form>td>div>input:hover,
.dynamic-form>td>div>input:active,
.dynamic-form>td>div>input:focus {
    border-color: var(--bs-body-color)!important;
    box-shadow: none!important;
}

.form-control:disabled {
    background-color: var(--disabled-light)!important;
    opacity: 1;
}

.form-control.is-invalid,
.is-invalid ~ .select2-container .select2-selection {
    border-color: var(--danger)!important;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e94b00'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
}

.invalid-feedback {
    display: block;
    font-size: 1rem;
    color: var(--danger);

}

.invalid-feedback strong, .has-error strong {
    font-weight: normal;
}

.asteriskField {
    color: var(--danger);
    margin-left: 0.12rem;
}

/* javascript main.js adds triangle to forms errors */

.fa-exclamation-triangle {
    margin-right: 0.31rem;
}

/* styles for custom crispy field InputWithAppendedAnchor  */


.input-group-appended-anchor.input-group .form-control{
	outline: 0;
	box-shadow: none;
	border-right-style: hidden;
}

.input-group-appended-anchor.input-group:hover .form-control{
	outline: 0;
	box-shadow: none;
	border-right-style: hidden;
}

.input-group-appended-anchor.input-group:hover .input-group-text {
	outline: 0;
	box-shadow: none;
    
}

.input-group-text {
    border-color: var(--secondary-light);
    transition: border-color 0.20s ease-out;
}

.input-group-appended-anchor > input.textInput:active > .input-group-text,
.input-group-appended-anchor > input.textInput:focus ~ .input-group-text,
.input-group-appended-anchor > input.textInput:hover > .input-group-text {
    border-color: var(--bs-body-color);
    transition: border-color 0.1s ease-in;
}

.input-group-appended-anchor.input-group .input-group-text {
    color: var(--dark);
    border-radius: 0.7rem;
    padding: 0.75rem 0.75rem 0.6rem 0.75rem;
}


.input-group-appended-anchor > .is-invalid ~ .input-group-text {
    border-color: var(--danger);
    padding: 0.75rem 0.75rem 0.6rem 0.75rem;
}

.input-group-appended-anchor.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) ~ .input-group-text {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

/* dropzone styles */

.dropzone-file-widget .dropzone {
    display: table-cell;
    border: 1px solid var(--secondary-light);
    border-radius: 0.71rem;
    width: 18.64rem;
    max-width: 18.64rem;
    height: 9.64rem;
    background-color: var(--white)!important;
}

.dropzone-file-widget .dropzone.dragover {
    background-color: rgba(0, 0, 0, .3);
}

.browse-link {
    display: block;
    color: var(--primary);
    text-decoration: underline;
}

.browse-link:hover {
    text-decoration: none;
}

.dropzone:hover {
    cursor: pointer;
}

.dropzone:hover > .browse-link {
    text-decoration: none;
    color: var(--dark);
}

.dropzone i.fa-cloud-upload {
    display: block;
    margin-top: 1.42rem;
    font-size: 2rem;
    color: var(--primary);
    pointer-events: none;
}

.dropzone .browse-link {
    pointer-events: none;
}

.dropzone-text {
    color: var(--dark);
    text-decoration: none !important;
}

.dropzone-browse {
    margin-top: 0 !important;
    margin-bottom: 2.19rem;
}

.dropzone i.fa-cloud-arrow-up {
    display: block;
    font-size: 1.75rem;
    color: var(--primary);
    pointer-events: none;
}

.dropzone-file-zone i.fa-cloud-arrow-up {
    font-size: inherit !important;
}

.dropzone-file-zone-browse .dropzone-file-info {
    margin-bottom: 0.81rem !important;
}

.dropzone-file-zone-browse i.fa-cloud-arrow-up {
    font-size: inherit !important;
}

.dropzone-file-zone-spacer{
    height: 40% !important;
}

.dropzone-file-zone-browse {
    height: 45% !important;
}

.dropzone-delete-image-button {
    width: fit-content;
    margin-left: auto;
    margin-top: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 0.94rem;
}

.dropzone-image {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 1.25rem;
    max-width: 9.75rem;
    min-width: -webkit-fill-available;
}

@-moz-document url-prefix() {
    .dropzone-image {
        min-width: -moz-available;
    }
}

.dropzone-info {
    margin-top: 2.19rem;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
}

.dropzone-file {
    margin-left: 1.25rem;
    color: var(--primary);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 0.5rem;
}
.dropzone-delete-file-button {
    margin-right: 1.25rem;
}

/* Date input */

.datepicker-control {
    position: relative;
}

.datepicker-control input {
    padding-left: 2.5rem;
    background-color: transparent !important;
}

.datepicker-control::before {
    font: var(--fa-font-solid);
    content: "\f073";
    margin: auto;
    position: absolute;
    top: 50%; left: 0.75rem; bottom: 0; right: 0;
    margin-top: -0.6rem;
    font-size: 1.2rem;
    z-index: -1;
}

.ui-state-default, .ui-state-hover {
    border: none !important;
    background-color: var(--white) !important;
    color: var(--primary) !important;
}

.ui-state-hover {
    color: var(--dark) !important;
    font-weight: $font-weight-bold !important;
}

.ui-state-active {
    background-color: var(--secondary-dark) !important;
    color: var(--white) !important;
    font-weight: $font-weight-bold !important;
    border-radius: 0.29rem;
}

.ui-datepicker {
    width: auto;
    padding: 0.5rem;
}

.ui-widget-header,
.ui-datepicker thead > tr {
    background-color: var(--white);
    border: none;
}

.ui-widget-header {
    color: var(--primary) !important;
}

.ui-datepicker td {
    width: 2.8rem;
    height: 2.4rem;
    text-align: center;
    /* padding: 0.4rem 0.6rem; */
}

.ui-widget.ui-widget-content {
    border: 2px solid var(--primary);
    border-radius: 8px;
    box-shadow: 0 .5rem 1rem rgba(0, 116, 118, .15)!important;
}

.ui-datepicker-prev::before {
    content: "\f04a";
}

.ui-datepicker-next::before,
.ui-datepicker-prev::before {
    font: var(--fa-font-solid);
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    margin-left: -0.5em;
    margin-top: -0.5em;
    position: absolute;
    color: var(--primary);
    cursor: pointer;
}

.ui-datepicker-next::before {
    content: "\f04e";
}

.ui-datepicker-next.ui-state-hover::before,
.ui-datepicker-prev.ui-state-hover::before {
    color: var(--dark);
}

.ui-datepicker-prev span, .ui-datepicker-next span {
    display: none !important;
}

.ui-datepicker-prev-hover {
    left: 0.14rem !important;
    top: 0.14rem !important;
}

.ui-datepicker-next-hover {
    right: 0.14rem !important;
    top: 0.14rem !important;
}

.ui-datepicker table {
    margin: 0;
}

.ui-datepicker td a {
    padding: 0 !important;
    text-align: center !important;
}

