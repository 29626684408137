/* # Footer styles */

footer {
	flex-basis: 6.25rem;
    margin-top: auto;

    .anthesis-logo {
        color: var(--accent);
    }

    &.bg-dark-primary {
        .anthesis-logo {
            color: var(--white);
        }
    }

}

#footer {
    width: 100%;
}

.footer-text-white {
    color: var(--white);
}

.footer-text-white:hover {
    color: var(--gray-dark);
}

.footer-container {
	padding-top: 3.5rem;
	padding-bottom: 2.5rem;
}


/* # Language switcher styles */
.footer_lang .select2-container  {
    width: auto !important;
    min-width: 50%;
}

.footer_lang .select2-container .select2-selection__rendered {
    font-family: sans-serif, 'FontAwesome';
    padding-left: 2rem;
    margin-right: 0.6rem;
}

/* globe icon */
.footer_lang .select2-container .select2-selection:before {
    content: "\f0ac";
    font-family: "FontAwesome";
    font-size: 1.25rem;
    position: absolute;
    bottom: 0.6rem;
 }

.footer_lang .select2-container .select2-selection:hover::before {
    color: var(--white);
}

/* dark theme */
.dark_lang .select2-container {
    --select2-font-color: var(--white);
    --select2-font-color-hover: var(--gray-dark);
    --select2-border-color: var(--white);
    --select2-border-color-hover: var(--gray-dark);
    --select2-border-color-open: var(--primary);
    --select2-background-color: var(--primary-dark);
    --select2-background-color-hover: var(--primary-dark);
}

/* custom open styles (a white background when the select is open) */
.dark_lang .select2-container.select2-container--open .select2-selection {
    background-color: var(--white);
}

.dark_lang .select2-container .select2-selection--multiple ul.select2-selection__rendered:after,
.dark_lang .select2-container .select2-selection--single .select2-selection__arrow b:after {
    color: var(--select2-font-color);
}

.dark_lang .select2-container.select2-container--open .select2-selection__rendered {
    color: var(--bs-body-color);
}

.dark_lang .select2-container.select2-container--open:hover .select2-selection__arrow b:after,
.dark_lang .select2-container.select2-container--open .select2-selection__arrow b:after {
    color: var(--primary);
}

/* dark theme globe icon */
.dark_lang .select2-container .select2-selection:before {
    color: var(--white);
}

.dark_lang .select2-container:not(.select2-container--open) .select2-selection:hover::before {
    color: var(--gray-dark);
}

.dark_lang .select2-container.select2-container--open .select2-selection:before {
    color: var(--primary);
}
