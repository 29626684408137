/*
    List of all the styles in this file

    Flatpage/help page toggle items
	CK editor in django admin

*/


/* Flatpage/help page toggle items start */
.panel-body.collapsible-item-body {
	margin-left: .56rem;
    margin-top: 1.25rem;
}

.panel {
    border-bottom: solid 3px #efefef !important;
}

.panel:first-of-type {
	border-top: solid 3px #efefef !important;
}

.collapsible-item-title-link * .fa-chevron-down {
	font-size: 1.63;
	color: #000 !important;
}

.collapsible-item-title-link {
	font-weight: $font-weight-bold;
	display: block;
    padding: 1.88rem .94rem;
    margin: -0.63rem -0.94rem;
	text-decoration: none !important;
}

.snippet_help_link {
	margin-bottom: 1.42rem;
}

/* terms and contitions container accordion */

.terms_privacy_accordions > .accordion {
    --bs-accordion-border-width: 0rem;
    --bs-accordion-border-radius: 0.71rem;
}

.terms_privacy_accordions > .accordion > .shadow {
    box-shadow: none!important;
}

.terms_privacy_accordions > .accordion > .accordion-item {
    border-bottom: solid 0.21rem var(--gray) !important;
    border-radius: 0 !important;
}

.terms_privacy_accordions > .accordion > .accordion-item:first-of-type {
    border-top: solid 0.21rem var(--gray);
    padding-top: 1.71rem!important;
}
