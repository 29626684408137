/*
_anchor_tags.css
=========
contains anchor tags styles
*/

a {
    text-decoration: none;
    color: var(--link-color);
}

a:hover,
a:focus,
a:active {
    color: var(--link-hover-color);
}

a.disabled {
    color: var(--disabled-dark) !important;
}
