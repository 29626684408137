.nav-banner {
    background-color: var(--primary-dark);

    .nav-link {
        display: inline-block;
        border-radius: 0!important;
        border: 0!important;
        padding: 0 2.85rem 0 2.85rem;
        color: var(--white);
        line-height: 1;
    }

}

nav > .nav-tabs {
    height: 5.28rem;
    margin-left: 12.14rem;
    padding-left: 0;
    border: 0;
    --bs-nav-link-color: var(--primary);
    --bs-nav-link-hover-color: var(--primary);
    --bs-nav-link-disabled-color: var(--disabled);
}

.nav-link-dropdown:hover {
    cursor: pointer;
}

.nav-link-dropdown > .dropdown > .shadow.dropdown-menu {
    border-radius: 0 !important;
}

.nav-link-dropdown > .dropdown > .dropdown-menu {
    border-radius: 0 !important;
    min-width: fit-content !important;
}

.nav-link-dropdown > .dropdown > .dropdown-menu > li {
    height: 3.14rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nav-link-dropdown > .dropdown > .dropdown-menu > li > .dropdown-item {
    white-space: nowrap;
    color: var(--link-color);
}

.nav-link-dropdown > .dropdown > .dropdown-menu {
    border-radius: none !important;
    margin-top: 2.5rem !important;
}

.nav-link-dropdown > .dropdown > .dropdown-menu > li > .dropdown-item:hover,
.nav-link-dropdown > .dropdown > .dropdown-menu > li > .dropdown-item:active {
    color: var(--dark) !important;
    --bs-dropdown-link-hover-bg: var(--white);
    --bs-dropdown-link-active-bg: var(--white);
}

.nav-link-dropdown > .dropdown > .nav-link.show {
    background-color: var(--white);
    color: var(--dark);
}

.nav-tabs > .nav-link:hover,
.nav-link-dropdown > .nav-link:hover,
.nav-link-dropdown > .nav-link:active,
.nav-link-dropdown > .dropdown > .nav-link:active,
.nav-link-dropdown > .dropdown > .nav-link:hover {
    color:var(--primary) !important;
    background: var(--white);
    border: 0;
}