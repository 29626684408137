/* notification css */
.notification-badge {
    display: inline-block;
    background-color: var(--accent);
    color: var(--black)!important;
    border-radius: 50%;
    min-width: 2.2rem;
    padding: 0.72rem 0.4rem 0.7rem 0.4rem;
    position: absolute;
    top: 45%;
    right: 0.429rem;
    transform: translate(-50%, -50%);
}

.notification-item {
    padding-left: 2.57rem !important;
}

#container-data-target-notifications i.fa-circle {
    display: inline-block;
    color: var(--primary);
    margin-top: 1.1rem;
    border-radius: 50%;
}

#container-data-target-notifications i.fa-eye {
    display: inline-block;
    color: var(--white);
    margin-right: -0.14rem;
}

.notification-totals {
    margin-top: -3.57rem;
}

.deadline-notifications {
    color: var(--danger);
}

.unread-notifications {
    color: var(--primary);
}

.total-notification {
    color: var(--dark);
}

.mark-as-read > i.fa-circle-check {
    font-size: 1.4rem;
}