.dropdown .dropdown-menu {
	--bs-dropdown-border-radius: 0 0 0.71rem 0.71rem;
	--bs-dropdown-border-color: var(--primary);
	--bs-dropdown-border-width: 0.15rem;
	--bs-dropdown-link-color: var(--primary);
    --bs-dropdown-link-active-color: var(--primary);
    --bs-dropdown-link-active-bg: var(--bs-dropdown-link-hover-bg);
    --bs-dropdown-link-hover-color: var(--primary);
    --bs-dropdown-link-hover-bg: var(--disabled-light);
	inset: 0 0 auto 0;
	transform: translate3d(0rem, 2.64rem, 0rem) !important;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown {
	position: relative;
	display: inline-block;
}

.dropdown .dropdown-menu li {
	padding: 0 0.25rem;
}

.dropdown .dropdown-menu li .dropdown-item {
	word-break: break-word;
	white-space: normal;
	border-radius: 0.71rem;
}

.dropdown .shadow.dropdown-menu {
	border-radius: 0.71rem !important;
	border: none !important;
}

.dropdown.sort-by-dropdown .dropdown-toggle::after {
    border: none;
}

.dropdown .btn-primary.dropdown-toggle.show {
	color: var(--primary) !important;
}

.dropdown .btn-secondary.dropdown-toggle.show,
.dropdown .btn-outline-secondary.dropdown-toggle.show {
	color: var(--secondary) !important;
}

.dropdown .btn-success.dropdown-toggle.show,
.dropdown .btn-outline-success.dropdown-toggle.show {
	color: var(--success) !important;
}

.dropdown .btn-info.dropdown-toggle.show,
.dropdown .btn-outline-info.dropdown-toggle.show {
	color: var(--info) !important;
}

.dropdown .btn-warning.dropdown-toggle.show,
.dropdown .btn-outline-warning.dropdown-toggle.show {
	color: var(--warning) !important;
}

.dropdown .btn-danger.dropdown-toggle.show,
.dropdown .btn-outline-danger.dropdown-toggle.show {
	color: var(--danger) !important;
}

.dropdown .btn-light.dropdown-toggle.show,
.dropdown .btn-outline-light.dropdown-toggle.show {
	color: var(--light) !important;
}

.dropdown .btn-dark.dropdown-toggle.show,
.dropdown .btn-outline-dark.dropdown-toggle.show {
	color: var(--dark) !important;
}

/* specific to the dropdown component (should be moved) */
.dropdown.dropdown-list-component .btn.dropdown-toggle.show {
	border-radius: 0.71rem 0.71rem 0 0;
	background-color: transparent;
	box-shadow: unset;
}

.dropdown.dropdown-list-component .dropdown-menu {
	border-radius: 0 0 0.71rem 0.71rem;
	inset: 0 0 auto 0;
	transform: translate3d(0rem, 2.64rem, 0rem) !important;
	border-top: 0rem;
	border-width: 0.14rem;
	width: 100%;
}