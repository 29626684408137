/*
_buttons.css
=========
contains bootstrap buttons and any custom buttons
*/

/* buttons */

.btn {
	--bs-btn-border-radius: 0.71rem;
	--bs-btn-padding-y: 0.75rem;
	--bs-btn-padding-x: 1.25rem;
	--bs-btn-box-shadow: none;
	--bs-btn-focus-box-shadow: none;
}

.btn.btn-sm {
	--bs-btn-padding-y: 0.25rem;
	--bs-btn-padding-x: 0.5rem;

}

/* Button primary */

.btn-primary {
	--bs-btn-color: var(--white);
    --bs-btn-bg: var(--primary);
    --bs-btn-border-color: var(--primary);
    --bs-btn-hover-color: var(--black);
    --bs-btn-hover-bg: var(--hover);
    --bs-btn-hover-border-color: var(--hover);
    --bs-btn-focus-shadow-rgb: 255,255,255;
    --bs-btn-active-color: var(--black);
    --bs-btn-active-bg: var(--primary);
    --bs-btn-active-border-color: var(--primary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);

	--bs-btn-disabled-color: var(--disabled-dark);
	--bs-btn-disabled-bg: var(--disabled);
	--bs-btn-disabled-border-color: var(--disabled);
}

.btn-primary:active,
.btn-primary:focus {
	background-color: var(--hover);
	border-color: var(--hover);
}

/* button outline primary, called by designers "Secondary" */

.btn-outline-primary {
    --bs-btn-color: var(--primary);
    --bs-btn-border-color: var(--primary);
	--bs-btn-bg: var(--white);
    --bs-btn-hover-color: var(--white);
    --bs-btn-hover-bg: var(--primary);
	--bs-btn-hover-border-color: var(--primary);
    --bs-btn-focus-shadow-rgb: 255,255,255;
    --bs-btn-active-color: var(--hover);
    --bs-btn-active-bg: var(--primary);
	--bs-btn-active-color: var(--white);
    --bs-btn-active-border-color: var(--primary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: var(--disabled-dark);
    --bs-btn-disabled-bg: transparent;
    --bs-gradient: none;
}

.btn-outline-primary:focus {
    background-color: var(--primary);
    border-color: var(--primary);
	box-shadow: none;
}

/* Bootstrap's Secondary button */

.btn-secondary {
	background-color: var(--secondary);
	border-color: var(--secondary);
}

.btn-secondary:hover {
	background-color: var(--secondary-dark);
	border-color: var(--secondary-dark);
}

.btn-secondary:focus {
	background-color: var(--secondary);
	border-color: var(--secondary);
}

.btn-outline-secondary {
	border: 0.1429rem solid var(--secondary);
	color: var(--secondary);
}

.btn-outline-secondary:hover {
	background-color: var(--secondary);
	border-color: var(--secondary);
}

.btn-outline-secondary:focus {
	background-color: var(--secondary);
	border-color: var(--secondary);
}

/* Success */

.btn-success {
	background-color: var(--success);
	border-color: var(--success);
}

.btn-success:hover {
	background-color: var(--success-dark);
	border-color: var(--success-dark);
}

.btn-success:focus {
	background-color: var(--success);
	border-color: var(--success);
}

.btn-outline-success {
	border: 0.1429rem solid var(--success);
	color: var(--success);
}

.btn-outline-success:hover {
	background-color: var(--success);
	border-color: var(--success);
}

.btn-outline-success:focus {
	background-color: var(--success);
	border-color: var(--success);
}

/* Info */

.btn-info {
	background-color: var(--info);
	border-color: var(--info);
}

.btn-info:hover {
	background-color: var(--info-dark);
	border-color: var(--info-dark);
}

.btn-info:focus {
	background-color: var(--info);
	border-color: var(--info);
}

.btn-outline-info {
	border: 0.14rem solid var(--info);
	color: var(--info);
}

.btn-outline-info:hover,
.btn-outline-info:focus {
	background-color: var(--info);
	border-color: var(--info);
}

/* Warning */

.btn-warning {
	background-color: var(--warning);
	border-color: var(--warning);
}

.btn-warning:hover {
	background-color: var(--warning-dark);
	border-color: var(--warning-dark);
}

.btn-warning:focus {
	background-color: var(--warning);
	border-color: var(--warning);
}

.btn-outline-warning {
	border: 0.14rem solid var(--warning);
	color: var(--warning);
}

.btn-outline-warning:hover,
.btn-outline-warning:focus {
	background-color: var(--warning);
	border-color: var(--warning);
}

/* Danger */

.btn-danger {
	background-color: var(--danger);
	border-color: var(--danger);
}

.btn-danger:hover {
	background-color: var(--danger-vivid);
	border-color: var(--danger-vivid);
}

.btn-danger:focus {
	background-color: var(--danger);
	border-color: var(--danger);
}

.btn-outline-danger {
	border: 0.14rem solid var(--danger);
	color: var(--danger) !important;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus {
	background-color: var(--danger);
	border-color: var(--danger);
}

/* Light */

.btn-light {
	background-color: var(--light);
	border-color: var(--light);
}

.btn-light:hover {
	background-color: var(--secondary-light);
	border-color: var(--secondary-light);
}

.btn-light:focus {
	background-color: var(--light);
	border-color: var(--light);
}

.btn-outline-light {
	border: 0.14rem solid var(--light);
	color: var(--light);
}

.btn-outline-light:hover,
.btn-outline-light:focus {
	background-color: var(--light);
	border-color: var(--light);
}

/* Dark */

.btn-dark {
	background-color: var(--dark);
	border-color: var(--dark);
}

.btn-dark:hover {
	background-color: var(--primary-dark);
	border-color: var(--primary-dark);
}

.btn-dark:focus {
	background-color: var(--dark);
	border-color: var(--dark);
}

.btn-outline-dark {
	border: 0.14rem solid var(--dark);
	color: var(--dark);
}

.btn-outline-dark:hover,
.btn-outline-dark:focus {
	background-color: var(--dark);
	border-color: var(--dark);
}

/* button link, called by designers "Tertiary" */

.btn-link {
	--bs-btn-color: var(--link-color);
	--bs-btn-hover-color: var(--link-hover-color);
	--bs-btn-active-color: var(--link-hover-color);
	--bs-btn-disabled-color: var(--disabled-dark);
	text-decoration: none;
	&:focus {
		color: var(--link-hover-color);
	}
}

/* button close */

/* 
however this is called "blue" it is currently changed to green 
todo: rename class (btn-close-primary) and update all occurances
this work should only be done when we switch to sass so we could use
sass variable for color
*/

/* button close */

.btn-close-primary {
    content: "\58";
    font-family: 'FontAwesome';
}

.btn-close-primary:focus {
    box-shadow: none;
}

/* btn-icons */

.btn-icons,
.btn-icons:focus {
	background-color: var(--gray);
	box-shadow: none!important;
	border-radius: 0.31rem;
	color: var(--primary)!important;
	--bs-text-opacity: 1;
	border-color: var(--gray);
	padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
}

.btn-icons:hover,
.btn-icons:active,
.btn-icon-active {
	color: var(--light)!important;
	border-color: var(--primary)!important;
	background-color: var(--primary)!important;
}

.btn-icons:active{
	box-shadow: inset 0 0 0 0.21rem var(--white)!important;
	border: 0.07rem solid var(--dark)!important;
	border-radius: 0.57rem;
}

.btn-primary-borderless {
    color: var(--primary);
    background-color: transparent;
    border: none;
}

.btn-primary-borderless:hover,
.btn-primary-borderless:focus,
.btn-primary-borderless:active,
.btn-primary-borderless.show {
    color: var(--dark);
    background-color: transparent;
    border: none;
}

.btn-primary-borderless:hover > .bg-primary,
.btn-primary-borderless:focus > .bg-primary,
.btn-primary-borderless:active > .bg-primary,
.btn-primary-borderless.show > .bg-primary {
    background-color: var(--dark)!important;
}