/* navbar */

nav.navbar {
    z-index: 3;
    --bs-navbar-padding-x: 1rem;
    --bs-navbar-padding-y: 1rem;
    --bs-navbar-hover-color: var(--hover);
    --bs-navbar-nav-link-padding-x: 1rem;
    --bs-navbar-brand-color: var(--accent);
    --bs-navbar-brand-padding-y: 0;
    background-color: var(--white);

    .nav-link {
        padding: 0 2.85rem;
        line-height: 1;
    }

    .nav .nav-item .nav-link:hover,
    .navbar-nav .nav-item .nav-link {
        color: var(--link-color);
    }

    .navbar-nav .nav-item .nav-link:hover,
    .navbar-nav .nav-item .nav-link:focus,
    .navbar-nav .nav-item .nav-link:active {
        color: var(--link-hover-color);
        background: var(--white)!important;
    }

    .navbar-brand {
        color: var(--accent);
        --bs-navbar-brand-hover-color: var(--accent);
    }

    .anthesis-logo {
        background-color: var(--accent);
        mask-image: url("../img/anthesis_logo.svg");
        -webkit-mask-image: url("../img/anthesis_logo.svg");
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        height: 2.53rem;
        width: 14.3rem;
    }

    .fa-bars:active,
    .fa-bars:hover,
    .nav-link:hover {
        color: var(--hover);
        background: var(--white);
        border: 0;
    }

    .navbar-toggler {
        border: none;
    }

    .navbar-toggler:focus{
        border: none;
        box-shadow: none;
    }

    .fa-bars,
    .fa-times {
        color: var(--primary);
        font-size: 1.42rem;
    }

    .fa-times {
        font-size: 2rem;
    }
}