
/* # Checkbox styles*/
input[type="checkbox"] {
    accent-color: var(--primary);
}

/* # Radio button styles */
 input[type="radio"] {
    accent-color: var(--primary);
}

input[type="radio"]:checked:hover {
    accent-color: var(--hover);
}

.form-check-input:checked {
    background-color: var(--primary);
    border-color: var(--primary);
    box-shadow: none;
}

.form-check-input:checked:hover {
    accent-color: var(--hover);
    border-color: var(--hover);
    box-shadow: none;
}

.form-check-input:focus,
.form-check-input:hover {
    accent-color: var(--hover);
    border-color: var(--secondary-light);
    box-shadow: none;
}

.form-check-input.is-invalid {
    border-color: var(--danger)!important;
}

.form-check-label {
    margin-left: 0.31rem;
    line-height: 1.8rem;
}

.form-check-input.is-invalid~.form-check-label {
    color: var(--dark)!important;
}

.checkboxinput.is-invalid {
    outline: auto var(--danger)!important;
    outline-offset: 0;
}

.form-check-input.is-invalid:checked {
    background-color: var(--primary);
}

@-moz-document url-prefix('') {
    .checkboxinput.is-invalid {
        box-shadow: 0 0 0 2pt var(--danger) !important;
        outline: 0!important;
        outline-offset: 0;
    }
}

.pretty input {
    margin: 0.3rem;
}