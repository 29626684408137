/*
_accordion.css
=========
contains customised bootstrap's accordion
*/

a[role="button"][aria-expanded="false"] > .expanded,
a[role="button"][aria-expanded="true"] > .collapsed {
	display: none;
}

.accordion-button:not(.collapsed)  {
	background-color: var(--white);
	box-shadow: none;
	border-color: rgba(0,0,0,.125);
	color: var(--dark);
}

.accordion-button:focus {
	box-shadow: none;
	border-color: rgba(0,0,0,.125);
}

.accordion-button:not(.collapsed)::after {
	content: "\f078";
	width: 1.4rem;
    height: 1.4rem;
	font-size: 1.2rem;
    font-weight: 300;
	color: var(--bs-accordion-active-color);
	background-image: none;
	transform: rotate(-90deg);
    font-family: "Font Awesome 6 Pro";
}

.accordion-button.collapsed::after {
    content: "\f078";
    height: 1.7rem;
    font-size: 1.3rem;
    font-weight: 300;
    background-image: none;
    font-family: "Font Awesome 6 Pro";
}

.accordion-item {
	border-radius: 0.71rem;
}

