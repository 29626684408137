#mobile-toggle-sidebar {
    height: 4rem;
    position: fixed;
    z-index: 4;
    background-color: var(--bs-gray-100);
    width: 100%;
    display: block;
    font-weight: bold;
    right: 0;
    

    i {
        font-size: 1.2rem;
    }

    &.collapsed {
        width: 4rem;
        border-radius: 0.4rem;
    }
}

@include media-breakpoint-down(lg) {
    body.menu-overlay {
        overflow: hidden;
    }
}

#sidebar-menu {
    --sidebar-icon-size: 18px;
    --sidebar-padding: 0.625rem;
    --sidebar-nav-link-padding: 0.8125rem;
    
    max-width: calc(16rem + var(--sidebar-nav-link-padding));
    line-height: var(--sidebar-icon-size);
    background-color: var(--bs-gray-100);
    transition: 0.2s;
    z-index: 4;
    padding: var(--sidebar-padding);

    i.fa-lg {
        width: 1.125rem;
    }

    .notification-counter {
        color: var(--danger-vivid);
    }

    .notification-dot {
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 4px solid var(--danger-vivid);
        right: 0;
        top: 0;
        display: none;
    }

    .nav-link {
        --bs-nav-link-color: var(--primary);
        --bs-nav-link-hover-color: var(--primary);
        --bs-nav-link-padding-y: var(--sidebar-nav-link-padding);
        --bs-nav-pills-link-active-bg: var(--primary);

        > .icon-wrapper {
            width: 18px;
            display: inline-block;
        }

        &:hover {
            background-color: var(--white);
            color: var(--primary);
        }

        &.active,
        &:active {
            .notification-counter {
                color: var(--white);
            }
            img {
                -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                filter: grayscale(100%);
                background-color: var(--white);
            }
        }
    }

    .nav-heading {
        padding: var(--sidebar-nav-link-padding) 1rem;
        color: var(--bs-gray-700);

        .divider {
            display: none;
        }
    }

    .logo-bar {
        padding: 0.875rem;

        > div {
            height: 2.375rem;
        }

        i {
            cursor: pointer;
        }

        .anthesis-logo {
            color: var(--accent);

            svg {
                width: 7rem;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &.closed {
            max-width: calc(calc((var(--sidebar-nav-link-padding) * 2) + (var(--sidebar-padding) * 2)) + var(--sidebar-icon-size));

            .sub-item,
            .app-menu,
            .app-menu-arrow {
                display: none;
            }

            .notification-dot {
                display: inline;
            }

            .anthesis-logo {
                display: none;
            }

            .nav-link {
                --bs-nav-link-padding-x: var(--sidebar-nav-link-padding);
                > span:not(:first-child) {
                    display: none;
                }
            }

            .nav-heading {
                height: calc((var(--sidebar-nav-link-padding) * 2) + var(--sidebar-icon-size));
                padding: 0;
                
                span {
                    display: none;
                }

                .divider {
                    margin: 0 auto;
                    height: 100%;
                    width: 1.75rem;
                    display: block;
                    position: relative;
                    &::after {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        height: 2px;
                        background: var(--bs-gray-700);
                        content: "";
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }

    
    @include media-breakpoint-down(lg) {
        &.collapsing {
            top: 4rem;
            position: fixed;
            transition: none;
        }
        &.collapse.show {
            transition: none;
            position: fixed;
            width: 100%;
            height: 100%;
            top: 4rem;
            left: 0;
            bottom: 0;
            display: block;
            max-width: 100%;
            overflow: scroll;
            z-index: 4;

            #toggle-sidebar {
                display: none;
            }
        }
    }
}
