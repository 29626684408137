/*
bootstrap_theme.css
===================
modifies bootstrap classes
applies everywhere across the site,
unless overridden by a more specific selector
*/


/* headers and header classess */

h1,
.h1 {
	font-size: calc(1.6rem + 1.5vw);
}

h2,
.h2 {
	font-size: calc(1.1rem + .9vw);
	font-weight: $font-weight-bold;
}

h3 {
	font-weight: $font-weight-bold;
}

h3,
.h3 {
	font-size: calc(0.9rem + .6vw);
}

h4,
.h4 {
	font-size: calc(0.9rem + .3vw);
}

h5,
.h5 {
	font-size: 1.14rem;
}

h6,
.h6 {
	font-size: 1rem;
}

/* Primary */
.text-primary {
	color: var(--primary) !important;
}

.bg-primary {
	background-color: var(--primary) !important;
}

/* Secondary */
.text-secondary {
	color: var(--secondary) !important;
}

.bg-secondary {
	background-color: var(--secondary) !important;
}

.bg-gray {
	background-color: var(--gray) !important;
}

.bg-dark-gray {
	background-color: var(--disabled);
}

.bg-dark-primary {
	background-color: var(--primary-dark);
}

/* Success */
.text-success {
	color: var(--success) !important;
}

.bg-success {
	background-color: var(--success) !important;
}

/* Info */
.text-info {
	color: var(--info) !important;
}

.bg-info {
	background-color: var(--info) !important;
}

.bg-info-subtle {
	background-color: var(--info-subtle) !important;
}

.border-info-subtle {
	border-color: var(--info-subtle-dark) !important;
}
/* Warning */
.text-warning {
	color: var(--warning) !important;
}

.bg-warning {
	background-color: var(--warning) !important;
}

/* Danger */
.text-danger {
	color: var(--danger) !important;
}

.bg-danger {
	background-color: var(--danger) !important;
}

.alert-danger {
    --bs-alert-color:  var(--danger);
    --bs-alert-bg: var(--danger-transparent);
    --bs-alert-border-color: var(--danger);
}

/* Light */
.text-light {
	color: var(--light) !important;
}

.bg-light {
	background-color: var(--light) !important;
}

/* Dark */
.text-dark {
	color: var(--dark) !important;
}

.bg-dark {
	background-color: var(--dark) !important;
}


/* card */

.card {
	box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.28rem 0.85rem;
}

/* todo too wide css selector */

.card h5 {
    width: 13rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* table */

.table.table-striped thead {
	background: var(--disabled) 0% 0% no-repeat padding-box;
}

.table.table-striped tbody tr:nth-of-type(odd) {
	background: var(--gray) 0% 0% no-repeat padding-box;
}

.table th,
.table td {
	border: 0;
}

.table th:first-child,
.table td:first-child {
	border-radius: 0.71rem 0 0 0.71rem;
}

.table th:last-child,
.table td:last-child {
	border-radius: 0 0.71rem 0.71rem 0;
}

.table td,
.table th {
    padding-left: 2.14rem;
}

thead > tr {
    background-color: var(--disabled);
}

/* todo see if we can use "table-stripped" from bootstrap instead */

.table>tbody>tr:nth-child(even)>td {
   background-color: var(--gray-light);
 }

.table>tbody>tr>td, .table>tbody>tr>th,
.table>thead>tr>td, .table>thead>tr>th {
  border: 0
}

.table tr {
    height: 4.78rem;
    vertical-align: middle;
}

/* breadcrumb */

.breadcrumb a {
	color: var(--primary);
	text-decoration: none;
}

/* forms */

.input-group:hover .form-control,
.input-group:hover .input-group-text {
	outline: 0;
	box-shadow: none;
	border-color: var(--dark);
}

.input-group.input-group-search .form-control {
	border-left: 0 !important;
}

.input-group.input-group-search .input-group-text {
	border-radius: 0.71rem;
	border-right: 0 !important;
}

/* progress bar */

.progress-bar {
	background-color: var(--primary);
}

.progress-bar-state-25 {
	background-color: var(--progress-bar-state-25) !important;
}

.progress-bar-state-50 {
	background-color: var(--progress-bar-state-50) !important;
}

.progress-bar-state-75 {
	background-color: var(--progress-bar-state-75) !important;
}

.progress-bar-state-100 {
	background-color: var(--progress-bar-state-100) !important;
}

.form-control {
	line-height: var(--bs-body-line-height);
}
