
/* organisation css */
.organisation-tabs {
    margin-left: 0.71rem;
}

.current-tab {
    color: var(--dark);
    pointer-events: none;
    cursor: default;
}

.current-tab-border {
    border-bottom: 0.36rem solid var(--dark);
    padding-bottom: 0.86rem;
}

.next-tab-border:hover {
    border-bottom: 0.36rem solid var(--primary);
    padding-bottom: 0.86rem;
}

.settings-pages,
.staff-members-page {
    margin-left: 10.57rem;
}

.staff-members-page {
    height: 28.12rem;
}

.member-options {
    margin-left: -1.75rem;
}

.org-members-avatar {
    width: 2.86rem;
    height: auto;

}

.member-options > .dropdown-menu {
    --bs-dropdown-border-radius: 0.71rem 0.71rem;
    --bs-dropdown-padding-x: 1.21rem;
    --bs-dropdown-padding-y: 0.93rem;
    --bs-dropdown-item-padding-x: 0.93rem;
    --bs-dropdown-item-padding-y: 0.93rem;
    inset: 0 0 auto auto;
}

#div_id_membership > .form-control:disabled {
    margin-bottom: -2rem;
}

.org-invite-btn {
    margin-right: 2.71rem;
}

.join-org-btn,
.org-creat-btn  {
    margin-right: 16%;
}

.invite-return-btn {
    margin-top: -2.14rem;
}

.org-return-btn {
    margin-top: -4.37rem;
}

.delete-domain {
    color: var(--primary);
    position: relative;
    top: -2.21rem;
    padding-right: 1rem;
    font-size: 1.42rem;
}

#container-data-target-select_organisation h3 {
    margin: 1rem 1rem;
}

/* organisation and profile css */

.set-new-password,
.form-add-row {
    line-height: 1.81rem;
    text-decoration: underline;
}

.set-new-password:hover,
.form-add-row:hover {
    text-decoration: none;
}

/* profile css */

.disconnect-org-btn {
    margin-left: 1.071rem;
}

.org-disconnect-dialog {
    position: relative;
    bottom: 2.21rem;
    padding-right: 0.71rem;
}

.settings-btn-right {
    margin-right: -5.71rem;
}

.profile-card-headers {
    display: block;
    margin-bottom: 0.35rem;
    font-size: 0.78rem;
}

.profile-card-text {
    display: block;
    color: var(--dark);
    font-size: 1rem;
    font-weight: $font-weight-bold;
}

.fakePasswordLabel {
    font-size: 1rem;
    line-height: .75rem;
    margin-top: .31rem;
    margin-bottom: .88rem;
}

/* company employer card */

.show-all-container {
    margin-top: 1.07rem;
    margin-left: -15.25rem;
    height: 1.88rem;
}

.show-all {
    display:block;
    text-align:center;
    font-size: 1em;
    padding: .81rem;
    text-decoration: underline;
}

.show-all:hover {
    text-decoration: none;
}

/* My organisation Members table */

.not-admin-cross {
    color: var(--danger);
    font-size: 1.57rem;
    padding-left: 1rem;
}

.is-admin-check {
    color: var(--success);
    font-size: 1.42rem;
    padding-left: 1rem;
}

