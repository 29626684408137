:root {
	--primary-font: 'PP Neue Montreal', sans-serif;

}

@font-face {
	font-family: "PP Neue Montreal";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('../fonts/PP_Neue_Montreal/WEB/PPNeueMontreal-Regular.woff2') format('woff2');
	font-stretch: normal;
}

@font-face {
	font-family: "PP Neue Montreal";
	font-style: light;
	font-weight: 300;
	font-display: swap;
	src: url('../fonts/PP_Neue_Montreal/WEB/PPNeueMontreal-Light.woff2') format('woff2');
	font-stretch: normal;
}

@font-face {
	font-family: "PP Neue Montreal";
	font-style: medium;
	font-weight: 500;
	font-display: swap;
	src: url('../fonts/PP_Neue_Montreal/WEB/PPNeueMontreal-Medium.woff2') format('woff2');
	font-stretch: normal;
}

@font-face {
	font-family: "PP Neue Montreal";
	font-style: bold;
	font-weight: 700;
	font-display: swap;
	src: url('../fonts/PP_Neue_Montreal/WEB/PPNeueMontreal-Bold.woff2') format('woff2');
	font-stretch: normal;
}

@font-face {
	font-family: "PP Neue Montreal";
	font-style: semi-bold;
	font-weight: 600;
	font-display: swap;
	src: url('../fonts/PP_Neue_Montreal/WEB/PPNeueMontreal-SemiBold.woff2') format('woff2');
	font-stretch: normal;
}

@font-face {
	font-family: "PP Neue Montreal";
	font-style: thin;
	font-weight: 100;
	font-display: swap;
	src: url('../fonts/PP_Neue_Montreal/WEB/PPNeueMontreal-Thin.woff2') format('woff2');
	font-stretch: normal;
}

@font-face {
	font-family: "Simplon Mono";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("../fonts/Simplon_Mono/desktop_files/SimplonMono-Regular.otf") format("opentype");
	font-stretch: normal;
}

@font-face {
	font-family: "Cigars";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("../fonts/Cigars/Desktop/HW Cigars Regular.otf") format("opentype");
	font-stretch: normal;
}
