/*
_pagination.css
=========
css to customise bootstrap's pagination used mainly
in components (container) and other places where
.pagination class is applied
*/

.pagination {
    --bs-pagination-active-bg: transparent;
    --bs-pagination-active-color: var(--dark);
    --bs-pagination-color: var(--primary);
    --bs-pagination-hover-color: var(--primary);
    --bs-pagination-focus-color: var(--primary);
    --bs-pagination-focus-box-shadow: none;
    /* borders */
    --bs-pagination-border-color: var(--white);
    --bs-pagination-disabled-border-color: var(--white);
    --bs-pagination-hover-border-color: var(--white);
    --bs-pagination-active-border-color: var(--white);
}