/*
component.css
===============
this is collection of styles for various components
*/

/* todo - please do not add css to this file!
Each component should have it's own css file and import it in media class
*/

/*# Bread crumbs styles*/

.breadcrumbs-indented {
    margin-left: 10rem;
    padding-left: 3rem;
}

.crumbs-container {
    margin-top: 1.42rem;
}

.crumbs {
    font-weight: $font-weight-bold;
    color: var(--link-color);
}

.last_crumb {
    color: var(--secondary);
}

/* Filters and search */
.filter-search input[type="search"] {
	border: 0.07rem solid var(--gray) !important;
    background-color: var(--gray) !important;
    padding-left: 2.57rem;
}

.search_bar_container {
    width: 26.57rem;
}

.filter-search input[type="search"]:hover,
.filter-search input[type="search"]:focus {
	border: 0.07rem solid var(--dark) !important;
}

.filter-search {
    position: relative;
}

.filter-search i.fa-magnifying-glass {
    position: absolute;
    left: 0.85rem;
    top: 1rem;
    font-size: 1.2rem;
}

.filter-search i.fa-xmark {
    display: none;
}

/* cancel/clear psuedo element for search bar */
input[type="search" i]::-webkit-search-cancel-button,
/* for firefox browser */
.filter-search input[type="search"]:not(:placeholder-shown):hover ~ i.fa-xmark,
.filter-search input[type="search"]:not(:placeholder-shown):focus ~ i.fa-xmark {
    filter: brightness(10%);
    content: "58";
    font-family: "Font Awesome 6 Pro";
    font-size: 1.03rem;
    padding-top: 0.05rem;
    padding-right: 0.078rem;
}


.btn-filters {
    color: var(--primary);
    background-color: var(--white);
    border: none;
}

.btn-filters:hover,
.btn-filters:focus,
.btn-filters:active,
.btn-filters.active {
    color: var(--white)!important;
    background-color: var(--dark)!important;
}

@-moz-document url-prefix() {
    .filter-search input[type="search"] {
        padding-right: 2.57rem;
    }

    .filter-search input[type="search"]:not(:placeholder-shown):focus ~ i.fa-xmark,
    .filter-search input[type="search"]:not(:placeholder-shown):hover ~ i.fa-xmark {
        display: block;
        position: absolute;
        font-size: 1.02rem;
        top: 0.7rem;
        right: 0.9rem;
        color: var(--primary);
        padding: 0.36rem;
        z-index: 1;
    }

    div.container:nth-child(2) > div:nth-child(2) .filter-bar .filter-search {
        width: 36%;
    }

    div.container-xl:nth-child(3) .filter-bar .filter-search {
        width: 36%!important;
    }

}

#sortDropdownMenuButton:hover,
#sortDropdownMenuButton:active,
#filterDropdownMenuButton:hover,
#filterDropdownMenuButton:active,
#filterDropdownMenuButton:hover > .totalchecked {
    color: var(--dark);
    background-color: var(--bs-btn-bg);

}

#dropdown-filter {
    color: var(--dark);
}

#dropdown-filter label.form-label {
    font-weight: $font-weight-bold;
}

.dropdown.sort-by-dropdown .dropdown-toggle::after {
    border: none;
}

.sort-by-dropdown ul {
    color: var(--dark);
    list-style-type: none;
}

.sort-by-dropdown .dropdown-menu.multiple-columns {
	width: auto;
}

.sort-by-dropdown .multiple-columns .sort-by-col-title {
    color: var(--dark);
    font-weight: $font-weight-bold;
    padding: 0 1.25rem;
}

.sort-by-dropdown .multiple-columns ul {
    padding: 0;
    margin: 0;
}

.sort-by-dropdown .multiple-columns .choice-label,
.sort-by-dropdown .multiple-columns .sort-by-col-title {
    font-size: 1rem;
}

.sort-by-dropdown .multiple-columns .choice-label,
.sort-by-dropdown .multiple-columns .choice-label input[type='radio']{
    vertical-align: middle;
}

.sort-by-dropdown .multiple-columns .choice-label input[type='radio']{
    margin: 0 0 0.1rem 0;
}

.sort-by-dropdown .multiple-columns .choice-label input[type='radio']:hover{
    cursor: pointer;
}

.sort-by-dropdown .multiple-columns .choice-label:hover {
    cursor: pointer;
}

.sort-by-dropdown .multiple-columns .sort-by-col-title {
    color: var(--dark);
    font-weight: $font-weight-bold;
    padding: 0 1.25rem;
}

.sort-by-dropdown .multiple-columns ul {
    padding: 0;
    margin: 0;
}


.search-bar {
    position: relative;
    width: 28.35rem;
}

.search-bar .fa-search {
    position: absolute;
    top: 1.21rem;
    left: 1.07rem;
    color: var(--dark);
}

.search-bar span {
    position: absolute;
    right: 1.21rem;
    top: 0.92rem;
    padding: 0.14rem;
}

/* makes space between select filters */
.filter-bar > .select2-container {
    margin-left: 1rem !important;
}


/* # Application banner styles*/
.application-banner-row {
    height: 10.57rem;
}

.application-banner-button {
    background-color: var(--white);
    width: 3.64rem;
    height: 3.64rem;
    margin-right: 0.71rem;
    border-radius: 0.71rem;
    &:hover, &:active {
        color:var(--dark);
    }
}

a:has(.application-banner-button) {
    color: var(--primary);
}

.header-logo-container {
    border-radius: 1.42rem;
    margin-top: 3.28rem;
    background-color: var(--white);
    width: 10.57rem;
    height: 10.57rem;
    background-size: cover;
    z-index: 1;
}

.header-logo-container > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 1.28rem;
}

.app-heading {
    margin-top: 3.28rem;
    margin-left: 2.5rem;
    font-weight: $font-weight-bold !important;
}

.no-app-logo {
    margin-left: 0.21rem;
}

.purchase-membership {
    margin-left: 2.42rem;
    margin-top: -0.35rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* #Ajax loader for container */
.ajax-loader {
    width: 100%;
    --loader-size: 60px;
    height: var(--loader-size);
    text-align: center;
}
.ajax-loader.page {
    --loader-size: 120px;
}
.ajax-loader.small {
    --loader-size: 30px;
}

.ajax-loader::before{
    content: " " !important;
    display: inline-block !important;
    position: relative !important;
    left: 0 !important;
    right: 0 !important;
    width: var(--loader-size);
    height: var(--loader-size);
    background-image: url("../img/loading.svg");
    background-size: cover;
}

.ajax-loader.center{
    position: absolute !important;
    left: 0;
    right: 0;
    top: 50%;
    margin: calc(-1*var(--loader-size)/2) auto 0;
}

@media (max-width: 768px){
    .application-banner-row {
        margin-right: -0.57rem;
    }
}

@media (max-width: 991px) {
    /* application banner */
    .no-app-logo {
        margin-left: 0.21rem!important;
        font-size: 2rem!important;
        height: 100%!important;
        display: flex!important;
        margin-top: auto!important;
        margin-bottom: auto!important;
        align-items: center!important;
    }
    .banner-header-container {
        display: contents !important;
    }
    .app-heading {
        font-size: 2rem!important;
        height: 100%!important;
        display: flex!important;
        margin-top: auto!important;
        margin-bottom: auto!important;
        align-items: center!important;
    }
    .sub-heading{
        display: table!important;
        margin: auto!important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .no-app-logo {
        margin-left: 0.42rem!important;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .application-banner-row {
        margin-right: 0;
    }
}


@media(max-width: 551px) {
    .nav-link-dropdown > .dropdown > .dropdown-menu {
        margin-top: -18px !important;
    }
}

@media (min-width: 551px) and (max-width: 590px) {
    .nav-link-dropdown > .dropdown > .dropdown-menu {
        margin-top: -12px !important;
    }
}

@media(min-width: 590px) and (max-width: 900px) {
    .nav-link-dropdown > .dropdown > .dropdown-menu {
        margin-top: 0px !important;
    }
}
