 /* variables */
 .select2-container {
    --select2-padding: 0.75rem;
    --select2-padding-bottom: 0.6rem;
    --select2-border-radius: 0.7rem;
    --select2-font-color: var(--primary);
    --select2-font-color-hover: var(--white);
    --select2-background-color: var(--white);
    --select2-background-color-hover: var(--primary);
    --select2-border-color: var(--primary);
    --select2-border-color-hover: var(--primary);
    --select2-border-color-open: var(--primary);

    --transparent: rgba(0, 0, 0, 0);  /* move to colors? */
}

#pagination .select2-container {
    --select2-padding: .75rem 0.4rem .7rem .2rem;
}

#pagination .select2-container .select2-selection--single .select2-selection__arrow b {
    right: 0.6rem;
}
 
 /* inactive state (user not interacting) */
 .select2-container .select2-selection {
    height: auto;
    padding: var(--select2-padding);
    padding-bottom: var(--select2-padding-bottom);
    border-color: var(--select2-border-color);
    border-radius: var(--select2-border-radius);
    background-color: var(--select2-background-color);
 }

.select2-container .select2-selection .select2-selection__rendered {
    line-height: var(--bs-body-line-height);
    color: var(--select2-font-color);
}

 /* hover state */
.select2-container .select2-selection:hover {
    background-color: var(--select2-background-color-hover);
    border-color: var(--select2-border-color-hover);
}

.select2-container .select2-selection:hover .select2-selection__rendered {
    color: var(--select2-font-color-hover);
}

.select2-container.select2-container--disabled .select2-selection:hover {
    background-color: var(--disabled-light);
}

.select2-container.select2-container--disabled .select2-selection:hover .select2-selection__rendered {
    color: var(--primary);
}

/* open state */
.select2-container.select2-container--open .select2-selection {
    border-color: var(--select2-border-color-open);
}

.select2-container.select2-container--open .select2-results__option--highlighted[aria-selected] {
    background-color: var(--primary);
}

.select2-container.select2-container--open.select2-container--below .select2-selection {
    border-bottom-color: var(--select2-background-color);
}

.select2-container.select2-container--open.select2-container--above .select2-selection {
    border-top-color: var(--select2-background-color);
}

/* dropdown and search */
.select2-dropdown {
    border: 1px solid var(--primary);
    border-radius: var(--select2-border-radius);
}

.select2-dropdown .select2-results__options::-webkit-scrollbar {
    width: 1.2rem;
}

.select2-dropdown .select2-results__options::-webkit-scrollbar-track {
    background-color: var(--light);
    background-clip: padding-box;
    border: 0.7rem solid var(--transparent);
}

.select2-dropdown .select2-results__options::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: var(--primary);
    border-right: 0.7rem solid  var(--transparent);
    height: 3rem;
}

.select2-dropdown .select2-results__options::-webkit-scrollbar-button {
    display: none;
}

/* color for scrollbar in dropdown select menus for Firefox browser */
@-moz-document url-prefix('') {
    .select2-results__options {
        scrollbar-color: var(--primary) var(--light);
        scrollbar-width: thin;
        margin-right: 0.5rem;
    }
}

.select2-dropdown .select2-search--dropdown .select2-search__field {
    border: 1px solid var(--secondary-light);
    border-radius: var(--select2-border-radius);
}

/* multi-select specifics */
.select2-container .select2-selection--multiple {
    padding: 0.1rem 0.6rem 0rem;
    min-height: 3rem;
    height: auto;
 }
 
.select2-container .select2-selection--multiple ul.select2-selection__rendered {
    padding: 0 2rem 0 0.6rem;
}

.select2-container .select2-selection--multiple .select2-search--inline .select2-search__field {
    margin-top: 0.5rem;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none;
    -webkit-mask-image: none;
    background-color: transparent;
}

.select2-container .select2-selection--multiple .select2-selection__choice {
    background-color: var(--dark);
    color: var(--white);
    border-radius: 0.5rem;
    margin: 0.5rem 0.2rem 0.2rem 0.36rem;
    padding: 0.2rem 0.43rem;
    border: none;
}


/**
the arrow (inactive state)
for multiple selects, a pseudo element is added as there is no arrow by default
for single selects, the existing arrow is transformed
**/
.select2-container .select2-selection--multiple ul.select2-selection__rendered:after,
.select2-container .select2-selection--single .select2-selection__arrow {
    top: 0rem;
    right: 0rem;
    height: 3rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: none;
    width: 0;
    height: 0;
    margin-top: 0;
    margin-left: -0;
    position: absolute;
    top: 0;
    left: 50%;
}

.select2-container .select2-selection--multiple ul.select2-selection__rendered:after,
.select2-container .select2-selection--single .select2-selection__arrow b:after {
    content: '\f078';
    color: #000;
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    border: none;
    height: 1.267rem;
    margin: 0;
    position: absolute;
    top: 0.927rem;
    left: auto;
    right: 0.333rem;
    transform: rotate(270deg);
}

.select2-container .select2-selection--multiple ul.select2-selection__rendered:after {
    right: 0.933rem;
}

/* the arrow (hover state) */
.select2-container .select2-selection--multiple:hover ul.select2-selection__rendered:after,
.select2-container .select2-selection--single:hover .select2-selection__arrow b:after {
    color: var(--select2-font-color-hover);
}

/* the arrow (open state) */
.select2-container.select2-container--open .select2-selection--multiple ul.select2-selection__rendered:after,
.select2-container.select2-container--open .select2-selection--single .select2-selection__arrow b:after {
    line-height: 1.2rem;
    right: 0.267rem;
    transform: rotate(0deg);
}

.select2-container.select2-container--open .select2-selection--multiple ul.select2-selection__rendered:after {
    right: 0.893rem;
}

/* the arrow (hover state when open) */
.select2-container.select2-container--open .select2-selection--multiple:hover ul.select2-selection__rendered:after,
.select2-container.select2-container--open .select2-selection--single:hover .select2-selection__arrow b {
    color: var(--select2-font-color-hover);
}
